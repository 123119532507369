import React, { useEffect, useState } from "react";
import { getMedia } from "../Service/Api";
import { Link } from 'react-router-dom'
import {SlideshowLightbox} from 'lightbox.js-react'
import LazyLoad from 'react-lazyload';
const ClassPhotographs = () => {
   const [data, setData] = useState([]);
   const [filterData, setFilteredData] = useState([]);
   const [selectedYear, setSelectedYear] = useState("2026"); // Default to "All"
   const [yearRanges, setYearRanges] = useState([]);
   const [searchQuery, setSearchQuery] = useState("");

   useEffect(() => {
      if(filterData) {
         console.log("filterData::",filterData);
      }
   }, [filterData]);

   useEffect(() => {
       const fetchData = async () => {
           const galleryData = await getMedia();
           setData(galleryData);

           // Extract unique years
           const uniqueYears = Array.from(
               new Set(galleryData.flatMap((item) => {
                   if (item.date) {
                       const year = new Date(item.date).getFullYear(); // Extract only the year
                       console.log(year)
                       return [year];
                   } else {
                       return [];
                   }
               }))
           );

           // Remove the map function for generating year ranges
           const uniqueYearRanges = uniqueYears.map(year => {
               return `${year}`;
           });

           uniqueYearRanges.sort((a, b) => b - a);
           console.log("Unique Year Ranges:", uniqueYearRanges); // Log uniqueYearRanges
           setYearRanges(uniqueYearRanges);
       };
       fetchData();
   }, []);

   useEffect(() => {
       // Filter data based on selected year
       let filteredData = data;
       if (selectedYear !== "All") {
           filteredData = filteredData.filter((item) => {
               if (item.date) {
                   const year = new Date(item.date).getFullYear();
                   return parseInt(selectedYear) === year;
               } else {
                   return false;
               }
           });
       }
       if (searchQuery) {
         filteredData = filteredData.filter((item) =>
             item.title.toLowerCase().includes(searchQuery.toLowerCase())
         );
     }
       setFilteredData(filteredData);
   }, [selectedYear,searchQuery, data]);
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li> Gallery </li>
                <li> Class Photographs  </li>
            </ul>
            <h2>Class Photographs </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
   <div className="container">
  
         <div className="row tabs-dynamic">
            <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
               <div className="count-val">
                  <p>Total Categories:  </p>
               </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
               <div className="month-selection">
                   
               <select id="chooseddlYear"
                                    value={selectedYear}
                                    onChange={(e) => setSelectedYear(e.target.value)}
                                    className="yearSelection"
                                >
                                    <option value={"All"}>All</option>
                                    {yearRanges.map((yr) => (
                                        <option key={yr} value={yr}>{yr}</option>
                                    ))}
                                </select>
               </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
               <div className="searchBox">
               <input
                    type="text"
                    id="myInput"
                    name="name"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Search Here.."
                />
               </div>
            </div>
         </div>
         <div className="row years year2023 showYear">

            
         {filterData.length > 0 && filterData.map((item, index) => (
             <div key={index} className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12 galleryCount" data-month="1">
               <div className="gal_new">
                  <article>
                     <div><LazyLoad>
                        <SlideshowLightbox>
                        <img src={`https://webapi.entab.info/api/image/${item?.attachments}`} alt="Loreto Convent School Delhi Cantt" className="img-fluid"/>
                       </SlideshowLightbox>
                       </LazyLoad>
                     </div>
                     <div className="gal-content">
                        <h4 className="class-name">{item.title}</h4>
                     </div>
                  </article>
               </div>
            </div>
             ))}
            {/* <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12 galleryCount" data-month="1">
               <div className="gal_new">
                  <article>
                     <div>
                        <SlideshowLightbox>
                        <img src="https://webapi.entab.info/api/image/LCSD/public/Images/gal-1.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/>
                       </SlideshowLightbox>
                     </div>
                     <div className="gal-content">
                        <h4 className="class-name">Class-A</h4>
                     </div>
                  </article>
               </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12 galleryCount" data-month="2">
               <div className="gal_new">
                  <article>
                     <div>
                        <SlideshowLightbox>
                        <img src="https://webapi.entab.info/api/image/LCSD/public/Images/gal-1.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/>
                       </SlideshowLightbox>
                     </div>
                     <div className="gal-content">
                        <h4 className="class-name">Class-A</h4>
                     </div>
                  </article>
               </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12 galleryCount" data-month="2">
               <div className="gal_new">
                  <article>
                     <div>
                        <SlideshowLightbox>
                        <img src="https://webapi.entab.info/api/image/LCSD/public/Images/gal-1.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/>
                       </SlideshowLightbox>
                     </div>
                     <div className="gal-content">
                        <h4 className="class-name">Class-A</h4>
                     </div>
                  </article>
               </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12 galleryCount" data-month="2">
               <div className="gal_new">
                  <article>
                     <div>
                        <SlideshowLightbox>
                        <img src="https://webapi.entab.info/api/image/LCSD/public/Images/gal-1.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/>
                       </SlideshowLightbox>
                     </div>
                     <div className="gal-content">
                        <h4 className="class-name">Class-A</h4>
                     </div>
                  </article>
               </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12 galleryCount" data-month="2">
               <div className="gal_new">
                  <article>
                     <div>
                        <SlideshowLightbox>
                        <img src="https://webapi.entab.info/api/image/LCSD/public/Images/gal-1.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/>
                       </SlideshowLightbox>
                     </div>
                     <div className="gal-content">
                        <h4 className="class-name">Class-A</h4>
                     </div>
                  </article>
               </div>
            </div>  */}
            
         </div>
        {/* <div className="row years year2022">
            <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12 galleryCount" data-month="1">
               <div className="gal_new">
                  <article>
                     <div>
                        <SlideshowLightbox>
                        <img src="https://webapi.entab.info/api/image/LCSD/public/Images/gal-1.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/>
                       </SlideshowLightbox>
                     </div>
                     <div className="gal-content">
                        <h4 className="class-name">Class-A</h4>
                     </div>
                  </article>
               </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12 galleryCount" data-month="2">
               <div className="gal_new">
                  <article>
                     <div>
                        <SlideshowLightbox>
                        <img src="https://webapi.entab.info/api/image/LCSD/public/Images/gal-1.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/>
                       </SlideshowLightbox>
                     </div>
                     <div className="gal-content">
                        <h4 className="class-name">Class-A</h4>
                     </div>
                  </article>
               </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12 galleryCount" data-month="2">
               <div className="gal_new">
                  <article>
                     <div>
                        <SlideshowLightbox>
                        <img src="https://webapi.entab.info/api/image/LCSD/public/Images/gal-1.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/>
                       </SlideshowLightbox>
                     </div>
                     <div className="gal-content">
                        <h4 className="class-name">Class-A</h4>
                     </div>
                  </article>
               </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12 galleryCount" data-month="1">
               <div className="gal_new">
                  <article>
                     <div>
                        <SlideshowLightbox>
                        <img src="https://webapi.entab.info/api/image/LCSD/public/Images/gal-1.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/>
                       </SlideshowLightbox>
                     </div>
                     <div className="gal-content">
                        <h4 className="class-name">Class-A</h4>
                     </div>
                  </article>
               </div>
            </div>
            <div className="load-more">
               <div className="next">Show More</div>
            </div>
         </div>  */}
      </div>

</section>
   </>
  )
}

export default ClassPhotographs