import React from 'react'
import LazyLoad from 'react-lazyload';
import { Link } from 'react-router-dom'
const MVPPExamination = () => {
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1">
        <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>
        </div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li> Outreach </li>
                <li> MVPP Examination</li>
            </ul>
            <h2>MVPP Examination </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
   <div className="container contact-page">
<div className="row">
<div className="col-xl-3 col-lg-3 col-md-3 col-12">
<LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/mvppexam.jpg"  className="img-fluid" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>
</div>
      <div className="col-md-9">
      <h1 className="head1">MVPP Examination </h1>
      <p>We take great pride in announcing that Aashita Priya of Class IX-C has qualified for the MVPP examination and secured a state rank of 39. MVPP is a scholarship examination conducted by the Science Branch of the Directorate of Education in Delhi for class 9 students. The examination was held on 17 November 2024.</p>
<p>The young achiever will receive a scholarship amount of Rs 5000.</p>
      </div>
      </div> 

</div>
</section>
   </>
  )
}

export default MVPPExamination