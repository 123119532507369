import React from 'react'
import LazyLoad from 'react-lazyload';
import { Link } from 'react-router-dom'
const Visitashanaraina = () => {
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1">
        <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>
        </div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li> Outreach </li>
                <li>  Visit to  Asha School, Naraina  </li>
            </ul>
            <h2> Visit to  Asha School, Naraina   </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
   <div className="container contact-page">
<div className="row">
      <div className="col-md-12">
      <h1 className="head1"> Visit to  Asha School, Naraina  </h1>
      <p>On 17 February 2025, Class VII B, along with their class teacher, Ms. Sonia Toby visited Asha School, Naraina, a school for differently abled children to sensitize our children and foster a sense of community.</p>
      </div>
      </div>
<div className="row align-images g-4">
<div className="col-xl-3 col-lg-4 col-md-6 col-12">
<LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/ashaschool1.jpg"  className="img-fluid" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>
</div>
<div className="col-xl-3 col-lg-4 col-md-6 col-12">
<LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/ashaschool2.jpg"  className="img-fluid" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>
</div>
<div className="col-xl-3 col-lg-4 col-md-6 col-12">
<LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/ashaschool3.jpg"  className="img-fluid" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>
</div>

<div className="col-xl-3 col-lg-4 col-md-6 col-12">
<LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/ashaschool4.jpg"  className="img-fluid" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>
</div>

<div className="col-xl-3 col-lg-4 col-md-6 col-12">
<LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/ashaschool5.jpg"  className="img-fluid" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>
</div>  
<div className="col-xl-3 col-lg-4 col-md-6 col-12">
<LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/ashaschool6.jpg"  className="img-fluid" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>
</div>  
<div className="col-xl-3 col-lg-4 col-md-6 col-12">
<LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/ashaschool7.jpg"  className="img-fluid" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>
</div>  
<div className="col-xl-3 col-lg-4 col-md-6 col-12">
<LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/ashaschool8.jpg"  className="img-fluid" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>
</div>  
<div className="col-xl-3 col-lg-4 col-md-6 col-12">
<LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/ashaschool9.jpg"  className="img-fluid" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>
</div>  
<div className="col-xl-3 col-lg-4 col-md-6 col-12">
<LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/ashaschool10.jpg"  className="img-fluid" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>
</div>  

</div>

</div>
</section>
   </>
  )
}

export default Visitashanaraina