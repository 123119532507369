import React from 'react'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
const CBSEDisclosure = () => {
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li> CBSE Disclosure  </li>
            </ul>
            <h2>CBSE Disclosure </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
<div className="container">
<div className="row">
<div className="col-md-12">
         <h1 className="centerhead animated bounceInLeft"> <span>CBSE</span> Disclosure</h1>
         <p><b>A: GENERAL INFORMATION</b></p>
         <div className="tablescroll">
            <table className="table table-bordered">
               <thead>
                  <tr>
                     <th className="text-center">S. No.   </th>
                     <th className="text-center">INFORMATION</th>
                     <th className="text-center">DETAILS</th>
                  </tr>
               </thead>
               <tbody className="text-center">
                  <tr>
                     <td>1 </td>
                     <td>NAME OF THE SCHOOL </td>
                     <td>Loreto Convent School</td>
                  </tr>
                  <tr>
                     <td>2 </td>
                     <td>AFFILIATION NO.(IF APPLICABLE) </td>
                     <td>2730135</td>
                  </tr>
                  <tr>
                     <td>3 </td>
                     <td>SCHOOL CODE (IF APPLICABLE) </td>
                     <td>25060</td>
                  </tr>
                  <tr>
                     <td>4 </td>
                     <td>COMPLETE ADDRESS WITH PIN CODE</td>
                     <td>Loreto Convent School, Parade Road, Delhi Cantt. New Delhi - 110010</td>
                  </tr>
                  <tr>
                     <td>5</td>
                     <td> PRINCIPAL NAME &amp; QUALIFICATION </td>
                     <td>Ms. Alice Rose D’Lima  M.A., B.Ed, SET</td>
                  </tr>
                  <tr>
                     <td>6</td>
                     <td> SCHOOL EMAIL ID</td>
                     <td><Link to="mailto:loretodelhi@gmail.com">loretodelhi@gmail.com</Link></td>
                  </tr>
                  <tr>
                     <td>7 </td>
                     <td>CONTACT DETAILS (LANDLINE/MOBILE)</td>
                     <td><Link to="tel:011-25692299">011-25692299</Link></td>
                  </tr>
               </tbody>
            </table>
         </div>
         <div className="clr20"></div>
         <p><b>B: DOCUMENTS AND INFORMATION</b></p>
         <div className="tablescroll">
            <table className="table table-bordered">
               <thead>
                  <tr>
                     <th className="text-center">S. No. </th>
                     <th className="text-center">DOCUMENTS/INFORMATION </th>
                     <th className="text-center">UPLOAD DOCUMENTS</th>
                  </tr>
               </thead>
               <tbody className="text-center">
                  <tr>
                     <td>1 </td>
                     <td>COPIES OF AFFILIATION/UPGRADATION LETTER AND RECENT EXTENSION OF AFFILIATION, IF ANY </td>
                     <td><Link to="https://webapi.entab.info/api/image/LCSD/public/pdf/disclosure/affiliatation.pdf#toolbar=0&amp;navpanes=0" target="_blank">View</Link></td>
                  </tr>
                  <tr>
                     <td>2 </td>
                     <td>COPIES OF SOCIETIES/TRUST/COMPANY REGISTRATION/RENEWAL CERTIFICATE, AS APPLICABLE </td>
                      <td><Link to="https://webapi.entab.info/api/image/LCSD/public/pdf/disclosure/society-registration.pdf#toolbar=0&amp;navpanes=0" target="_blank">View</Link></td>
                  </tr>
                  <tr>
                     <td>3 </td>
                     <td>COPY OF NO OBJECTION CERTIFICATE (NOC) ISSUED, IF APPLICABLE, BY THE STATE GOVT./UT</td>
                     <td><Link to="#">NA</Link></td>
                  </tr>

                  <tr>
                     <td>4 </td>
                     <td>COPIES OF RECOGNITION CERTIFICATE UNDER RTE ACT, 2009, AND IT’S RENEWAL IF APPLICABLE</td>
                      <td><Link to="https://webapi.entab.info/api/image/LCSD/public/pdf/disclosure/recognition-certificate.pdf#toolbar=0&amp;navpanes=0" target="_blank">View</Link></td>
                  </tr>

                  <tr>
                     <td>5 </td>
                     <td>COPY OF VALID BUILDING SAFETY CERTIFICATE AS PER THE NATIONAL BUILDING CODE</td>
                   <td><Link to="https://webapi.entab.info/api/image/LCSD/public/pdf/disclosure/Structural-Certificate.pdf#toolbar=0&amp;navpanes=0" target="_blank">View</Link></td>
                  </tr>

                  <tr>
                     <td>6 </td>
                     <td>COPY OF VALID FIRE SAFETY CERTIFICATE ISSUED BY THE COMPETENT AUTHORITY</td>
                     <td><Link to="https://webapi.entab.info/api/image/LCSD/public/pdf/disclosure/fire-safety.pdf#toolbar=0&amp;navpanes=0" target="_blank">View</Link></td>
                  </tr>

                  <tr>
                     <td>7 </td>
                     <td>COPY OF THE DEO CERTIFICATE SUBMITTED BY THE SCHOOL FOR AFFILIATION/UPGRADATION/EXTENSION OF AFFILIATION OR SELF CERTIFICATION BY SCHOOL</td>
                     <td><Link to="https://webapi.entab.info/api/image/LCSD/public/pdf/disclosure/deo-certificate.pdf#toolbar=0&amp;navpanes=0"  target="_blank">View </Link></td>
                  </tr>
                  <tr>
                     <td>8 </td>
                       <td>COPIES OF VALID WATER, HEALTH AND SANITATION CERTIFICATES </td>
                <td><Link to="https://webapi.entab.info/api/image/LCSD/public/pdf/disclosure/water-safety.pdf#toolbar=0&amp;navpanes=0" target="_blank">View</Link></td>
                  </tr>
                  <tr>
                     <td>9</td>
                       <td>SCHOOL INFRASTRUCTURE VIDEO </td>
                <td><Link to="https://youtu.be/FpKhq0U69Ds" target="_blank">View</Link></td>
                  </tr>
               </tbody>
            </table>
         </div>
         <div className="clr20"></div>
         <p><b>C: RESULT AND ACADEMICS</b></p>
         <div className="tablescroll">
            <table className="table table-bordered">
               <thead>
                  <tr>
                     <th className="text-center">S. No. </th>
                     <th className="text-center">DOCUMENTS/INFORMATION </th>
                     <th className="text-center">UPLOAD DOCUMENTS</th>
                  </tr>
               </thead>
               <tbody className="text-center">
                  <tr>
                     <td>1 </td>
                     <td>FEE STRUCTURE OF THE SCHOOL </td>
                      <td><Link to="https://webapi.entab.info/api/image/LCSD/public/pdf/disclosure/fees-structure.pdf#toolbar=0&amp;navpanes=0" target="_blank">View</Link></td>
                  </tr>
                  <tr>
                     <td>2 </td>
                     <td>ANNUAL ACADEMIC  CALENDAR</td>
                   <td><Link to="https://webapi.entab.info/api/image/LCSD/public/pdf/disclosure/academic-calendar.pdf#toolbar=0&amp;navpanes=0" target="_blank">View</Link></td>
                  </tr>
                  <tr>
                     <td>3 </td>
                     <td>LIST OF SCHOOL MANAGEMENT COMMITTEE (SMC) </td>
                    <td><Link to="https://webapi.entab.info/api/image/LCSD/public/pdf/disclosure/SMC.pdf#toolbar=0&amp;navpanes=0" target="_blank">View</Link></td>
                  </tr>
                  <tr>
                     <td>4 </td>
                     <td>LIST OF PARENTS TEACHERS ASSOCIATION (PTA) MEMBERS</td>
                <td><Link to="https://webapi.entab.info/api/image/LCSD/public/pdf/disclosure/SMC.pdf#toolbar=0&amp;navpanes=0" target="_blank">View</Link></td>
                  </tr>
                  <tr>
                     <td>5 </td>
                     <td>LAST Four-YEAR RESULT OF THE BOARD EXAMINATION AS PER APPLICABILITY </td>
                    
                   <td><Link to="https://webapi.entab.info/api/image/LCSD/public/pdf/disclosure/Board-Results.pdf#toolbar=0&amp;navpanes=0" target="_blank">View</Link></td>
                  </tr>
               </tbody>
            </table>
         </div>

         <p><b>D: STAFF (TEACHING)</b></p>
         <div className="tablescroll">
            <table className="table table-bordered">
               <thead>
                  <tr>
                     <th className="text-center">S. No. </th>
                     <th className="text-center">INFORMATION </th>
                     <th className="text-center">DETAILS</th>
                  </tr>
               </thead>
               <tbody className="text-center">
                  <tr>
                     <td>1</td>
                     <td>PRINCIPAL</td>
                     <td> 1</td>
                  </tr>
                  <tr>
                     <td rowspan="4">2</td>
                     <td>TOTAL NO. OF TEACHERS</td>
                      <td>-</td>
                  </tr>
                  <tr>
                   
                     <td>o PGT</td>
                      <td>14</td>
                  </tr>
                  <tr>
                     <td>o TGT</td>
                       <td>18</td>
                  </tr>
                  <tr>
                     <td>o PRT </td>
                      <td>23</td>
                  </tr>
                  
                  <tr>
                     <td>3  </td>
                     <td>DETAILS OF SPECIAL EDUCATOR </td>
                      <td>Ms. Shweta </td>
                  </tr>
                  <tr>
                     <td>4 </td>
                     <td> DETAILS OF COUNSELLOR AND WELLNESS TEACHER  </td>
                       <td>Ms. Tripti Singhal</td>
                  </tr>
               </tbody>
            </table>
         </div>
         <div className="clr20"></div>
         <p><b>E: SCHOOL INFRASTRUCTURE</b></p>
         <div className="tablescroll">
            <table className="table table-bordered">
               <thead>
                  <tr>
                     <th className="text-center">S. No. </th>
                     <th className="text-center">INFORMATION </th>
                     <th className="text-center">DETAILS</th>
                  </tr>
               </thead>
               <tbody className="text-center">
                  <tr>
                     <td>1</td>
                     <td>TOTAL CAMPUS AREA OF THE SCHOOL (IN SQUARE MTR) </td>
                       <td>40468</td>
                  </tr>
                  <tr>
                     <td>2</td>
                     <td>SIZE OF CLASS ROOMS (IN SQUARE MTR)</td>
                       <td>9.75x7.3</td>
                  </tr>
                  <tr>
                     <td>3</td>
                     <td> NO. AND SIZE OF LABORATORIES INCLUDING COMPUTER LABS (IN SQUARE MTR)</td>
                      <td>Approx. 12.0-12.5 x 7</td>
                  </tr>
                  <tr>
                     <td>4 </td>
                     <td>INTERNET FACILITY (Y/N)</td>
                     <td> YES</td>
                  </tr>
                  <tr>
                     <td>5</td>
                     <td> NO. OF GIRLS TOILETS</td>
                     <td>45</td>
                  </tr>
               </tbody>
            </table>
         </div>
      </div>
</div>
</div>
</section>
   </>
  )
}

export default CBSEDisclosure