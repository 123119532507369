import React from 'react'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
const VisitAanchalSpecialSchool = () => {
    return (
        <>
            <section className="page-header">
                <div className="page-header-bg"></div>
                <div className="page-header-shape-1"><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt" /></LazyLoad></div>
                <div className="container">
                    <div className="page-header__inner">
                        <ul className="thm-breadcrumb list-unstyled ml-0">
                            <li><Link to="/"> Home</Link></li>
                            <li> Outreach </li>
                            <li> Aanchal Special School Chanakyapuri - Class VII-A </li>
                        </ul>
                        <h2>Visit to Aanchal Special School Chanakyapuri  - Class VII-A</h2>
                    </div>
                </div>
            </section>
            <section className="innerbody">
                <div className="container contact-page">
                <div className="row">
            <div className="col-md-12">
              <h1 className="head1">Visit to Aanchal Special School, Chanakyapuri - Class VII-A </h1>
              <p>On 19th February 2025, Class VII A Students had the privilege of visiting Aanchal Special School, Chanakyapuri with Ms. Rashmi. Aanchal School provides care and vocational training for children with intellectual disabilities.  </p>
            </div>
          </div>
          <div className="row align-images g-4 mb-4">
            <div className="col-xl-3 col-lg-4 col-md-6 col-12">
              <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/aanchalVisit-1.jpg" className="img-fluid" alt="Loreto Convent School, Delhi Cantt" /></LazyLoad>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-12">
              <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/aanchalVisit-2.jpg" className="img-fluid" alt="Loreto Convent School, Delhi Cantt" /></LazyLoad>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-12">
              <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/aanchalVisit-3.jpg" className="img-fluid" alt="Loreto Convent School, Delhi Cantt" /></LazyLoad>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-12">
              <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/aanchalVisit-4.jpg" className="img-fluid" alt="Loreto Convent School, Delhi Cantt" /></LazyLoad>
            </div>
 

          </div>

                </div>
            </section>
        </>
    )
}

export default VisitAanchalSpecialSchool