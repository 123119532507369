import React, { useEffect, useState } from "react";
import { getOnlineRegistration } from "../Service/Api";
import LazyLoad from 'react-lazyload';
import { Link } from "react-router-dom";

const OnlineRegistration = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getOnlineRegistration();
        setData(result);
      } catch (error) {
        console.error("Error fetching online registration data:", error);
      }
    };
    fetchData();
  }, []);

  const emptyArray = [];

  return (
    <>
      <div className="online-reg">
        <ul>
       
           {/* <li className="group">
            <a
              className="custom-title links-urllink"
              href="https://webapi.entab.info/api/image/LCSD/public/pdf/SELECTED-STUDENT'S-FOR-CLASSES-I-X.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              LIST OF SELECTED STUDENTS CLASSES (I-X)
            </a>
          </li> */}


      
          {data.length > 0 ? (
            data.map((item, index) => (
              <React.Fragment key={index}>
                {item.attachments.length > 0 ? (
                  item.attachments.map((attachment, attachIndex) => (
                    <li className="group" key={attachIndex}>
                      <Link
                        className="custom-title links-urllink"
                        to="https://loretodelhi.campuscare.cloud/logon/TPLoginRegistration"
                        target="_blank"
                      >
                        {item.title}
                      </Link>
                      <Link
                        to={`https://webapi.entab.info/api/image/${attachment}`}
                        target="_blank"
                        className=""
                      >
                        <i className="bi bi-paperclip"></i>
                      </Link>
                    </li>
                  ))
                ) : (
                  <li className="group" key={`no-attachment-${index}`}>
                    <Link
                      className="custom-title links-urllink"
                      to="https://loretodelhi.campuscare.cloud/logon/TPLoginRegistration"
                      target="_blank"
                    >
                      {item.title}
                    </Link>
                  </li>
                )}
              </React.Fragment>
            ))
          ) : (
            emptyArray.map((item, index) => <div key={index}></div>)
          )}
        </ul>
      </div>
    </>
  );
};

export default OnlineRegistration;
