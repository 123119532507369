import React from 'react'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
const ManagementDetails = () => {
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li> Office Bearers </li>
                <li> Management Details  </li>
            </ul>
            <h2>Management Details </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
   <div className="container">
       <div className="row">
           <div className="col-lg-12">
             <div className="table-responsive innertable">
                    <table className="table">
                        <tbody>
                            <tr>
                                <th>SL. No. </th>
                                <th>Name</th>
                                <th>Designation</th>
                            </tr>
                            <tr><td> 1</td><td>Sr. Leena Lakra</td><td>President</td> </tr>
                            <tr><td>2</td><td>Sr. Igora Pinto</td><td>Secretary, Loreto Convent, Delhi</td></tr> 
                            <tr><td>3</td><td>Mrs. Alice Rose D'Lima</td><td>Principal, Loreto Convent School</td></tr>
                            
                            <tr><td>4</td><td>Sr. Mariana Bage</td><td>	Member </td></tr>
                            <tr><td>5</td><td>Sr. Greta Roberts</td><td>Member</td></tr>
                            <tr><td colspan="3"><b>Nominated Members</b></td></tr>
                            <tr><td>6</td><td>Sr. Elica Rebello</td><td>Principal, Carmel Convent School</td></tr>
                            <tr><td>7</td><td>Sr. Stella Joseph</td><td>Principal, Mater Dei School</td></tr>
                            {/* <tr><td>7</td><td>Sr. Tina Maria D'Souza</td><td>Principal, Carmel Convent School</td></tr> */}
                            <tr><td>8</td><td>Br. Robert Fernandes </td><td>Principal, St. Columba's School</td></tr>
                            <tr><td>9</td><td>Bro. Tomy Varghese</td><td>Principal, Mt St. Mary's School</td></tr>
                            <tr><td>10</td><td>Mr. V. Dayalu</td><td>HOS, GBSSS, DDE Nominee</td></tr>
                            {/* <tr><td>11</td><td>Mr. R L Meena</td><td>HOS, GBSSS, DDE Nominee</td></tr> */}
                            <tr><td>11</td><td>Ms.  Sheetal Labru</td><td>Principal, Ryan  International School</td></tr>
                             <tr><td>12</td><td>Mrs. Smriti Suresh</td><td>	Lawyer, Loreto Convent School</td></tr>
                            <tr><td>13</td><td>Mrs. Parameshwari Mamgain</td><td>Teacher Member</td></tr>
                            <tr><td>14</td><td>	Mrs. Rachna Mehrotra </td><td>Teacher Member</td></tr>
                             <tr><td>15	</td><td>Mrs. Binaifer Mascarenhas</td><td>	Parent Member</td></tr>
                             <tr><td>16</td><td>Mrs. Priyanjali Pillai</td><td>	Parent Member</td></tr>
                        </tbody>
                    </table>
                </div>
           </div>
           


       </div>
   </div>
</section>
   </>
  )
}

export default ManagementDetails